import React from 'react';
import ContactLayout from '../../components/layouts/ContactLayout';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const Contact = ({data}) => {
	
	const bannerImg = data.backgroundImage;

	const backgroundImg = getImage(bannerImg);
	const bgImage = convertToBgImage(backgroundImg);

	return (
		<ContactLayout>
			<main id="content" role="main">
				<div className="isolate bg-black border-b-5 border-yellow">
					<div className="container h-quarter-screen lg:h-half-screen relative overflow-hidden">
						<BackgroundImage className="w-full h-full relative bg-top bg-center bg-top bg-cover" Tag="div" {...bgImage}>						
							<div className="bg-gradient-to-r from-black w-1/2 h-full absolute top-0 left-0 z--2"></div>
							<div className="bg-gradient-to-l from-black w-1/2 h-full absolute top-0 right-0 z--2"></div>
							<div className="grid grid-cols-1 absolute top-0 w-full h-full">
								<div className="flex flex-col h-full">
									<div className="flex flex-grow w-full items-center">
										<h1 className="text-center text-white font-bold uppercase lg:text-4xl md:text-3xl sm:text-2xl text-base">THE CURIOSITY TO CHALLENGE CONVENTIONS. THE COURAGE TO FIGHT FOR WHAT IS RIGHT.</h1> 
									</div>										
								</div>
							</div>
						</BackgroundImage>
          </div>
				</div>

				<div className="isolate bg-white">
					<div className="container m-auto lg:pt-16 lg:pb-8 md:pt-10 md:pb-5 pt-8 pb-4">
            <div className="grid grid-cols-1">
              <h1 className="lg:text-4xl md:text-3xl text-2xl uppercase mb-0 font-bold wider">Contact Us</h1>							
            </div>
          </div>
				</div>

				<div className="isolate bg-white">
					<div className="container m-auto lg:pt-8 lg:pb-8 md:pt-10 md:pb-5 pt-8 pb-4">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-40">
							<div>
								<h1 className="lg:text-6xl md:text-5xl text-4xl mb-0 font-bold wider lg:pb-12 pb-8">
									Get in touch and let's work together	
								</h1>

								<hr className="border-yellow border-t-4 pb-4" />	
								
								<p className="lg:pt-8 lg:pb-4 pt-4 pb-4">
								Drop us a message and let’s see how we can create something truly special with you.	
								</p>
							</div>

							<div>
								<p className="pb-8">
									<strong>
									Find out how we can help your organisation:
									</strong>
									<br />
									<a href="mailto:hello@designtobias.com" className="text-pink">hello@designtobias.com</a>
								</p>

								<p className="pb-8">
									<strong>
									Talk to us about your training needs:
									</strong>
									<br />
									<a href="mailto:learning@designtobias.com" className="text-pink">learning@designtobias.com</a>
								</p>

								<p className="pb-8">
									<strong>
									Looking for a great place to work:
									</strong>
									<br />
									<a href="mailto:talent@designtobias.com" className="text-pink">talent@designtobias.com</a>
								</p>
							</div>

            </div>
          </div>
				</div>

				<div className="isolate bg-white">
					<div className="container m-auto lg:pt-8 lg:pb-8 pt-6 pb-4">
            <div className="grid grid-cols-1">
              				
            </div>
          </div>
				</div>

				<div className="isolate bg-white">
					<div className="container m-auto lg:pt-8 lg:pb-4 md:pt-5 md:pb-2 pt-4 pb-2">
            <div className="grid grid-cols-1">
             		
            </div>
          </div>
				</div>

			</main>
		</ContactLayout>
	)
}

export default Contact;

export const caseStudyListQuery = graphql`
query contactIndexPageAssets {
	backgroundImage: file(
    relativePath: {eq: "contact-banner.jpeg"}
  ) {
    childImageSharp {
      gatsbyImageData(
				width: 1140, 
				placeholder: BLURRED, 
				formats: [AUTO, WEBP, AVIF]
			)
    }
  }
}
`

